import { useState } from "react";
import { AxiosResponse } from "axios";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  Modal,
  TextField,
} from "@mui/material";
import * as yup from "yup";
import { Formik } from "formik";
import { useParams } from "react-router-dom";
import { isEmail } from "validator";
import http from "../../../utils/http";
import { toastMessage, errorToastMessage } from "../../../utils/toast";
import { InputWrapper, LabelStyle } from "../../Common/styles/form";
import {
  ModalBaseStyles,
  ModalHeader,
  ModalActionButtonStyles,
} from "../../Common/styles/modal";
let schema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .test("is-valid", "*Please enter a valid Email ID.", (value) =>
      value ? isEmail(value) : true
    )
    .required("Email is Required"),
});
type Props = {
  showModal: boolean;
  closeModal: () => void;
  refreshPage: () => void;
  data: any;
};
const EditEmailModal = ({
  showModal,
  closeModal,
  refreshPage,
  data,
}: Props) => {
  const [submitLoader, setSubmitLoader] = useState(false);
  const { id } = useParams();

  const submitHandler = async (values: any) => {
    try {
      setSubmitLoader(true);
      let res: AxiosResponse;
      const body = {
        ...values,
        studyId: id,
      };
      res = await http.patch(`/participants/${data?.id}`, body);
      toastMessage("success", res.data.message);
      setSubmitLoader(false);
      closeModal();
      refreshPage();
    } catch (err) {
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={{ ...ModalBaseStyles, minHeight: "20vh" }}>
        <ModalHeader title="Edit Participant Email" onCloseClick={closeModal} />
        <Formik
          initialValues={{
            email: data?.email || "",
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({ handleSubmit, getFieldProps, errors, touched }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="email">
                    Participant Email{" "}
                    <span style={{ color: "#f16262" }}>*</span>
                  </FormLabel>
                  <TextField
                    fullWidth
                    id="email"
                    {...getFieldProps("email")}
                    error={touched?.email && errors?.email ? true : false}
                    helperText={
                      touched?.email && errors?.email
                        ? (errors?.email as string)
                        : " "
                    }
                    placeholder="Enter an email address"
                  />
                </FormControl>
              </Box>
              <Box sx={ModalActionButtonStyles}>
                {!submitLoader ? (
                  <>
                    <Button onClick={closeModal} variant="outlined">
                      Cancel
                    </Button>
                    <Button type="submit" variant="contained">
                      Save
                    </Button>
                  </>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};
export default EditEmailModal;
