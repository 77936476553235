import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  ModalActionButtonStyles,
  ModalBaseStyles,
  ModalHeader,
} from "../../Common/styles/modal";
import { InputWrapper, LabelStyle } from "../../Common/styles/form";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import { AxiosResponse } from "axios";
import http from "../../../utils/http";
import { useAppDispatch } from "../../../Redux/hooks";
import { setParticipantToggle } from "../../../Redux/reducers/participantsSlice";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";

type Props = {
  openModal: boolean;
  participantId: string;
  participant: any;
  closeModal: () => void;
};

const ConfirmDropOffModal = ({
  openModal,
  participantId,
  closeModal,
  participant,
}: Props) => {
  const { id } = useParams();
  const [submitLoader, setSubmitLoader] = useState<boolean>(false);
  const [reason, setReason] = useState<string>("");
  const [date, setDate] = useState<string>("");
  const dispatch = useAppDispatch();

  const changeReasonHandler = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setReason(event.target.value);
  };

  const changeDateHandler = (val: DateTime | null) => {
    if (val && val.isValid) {
      setDate(val.toFormat("dd/MM/yyyy"));
    }
  };

  const submitHandler = async () => {
    try {
      if (!reason.trim()) {
        toastMessage("warning", "Reason cannot be empty!");
        return;
      }
      if (!date) {
        toastMessage("warning", "Date cannot be empty!");
        return;
      }
      setSubmitLoader(true);
      const body = {
        studyId: id,
        reason: reason,
        date: DateTime.fromFormat(date, "dd/MM/yyyy").toUTC().toISO(),
      };
      let res: AxiosResponse = await http.post(
        `/participants/${participantId}/drop-off`,
        body
      );
      toastMessage("success", res.data.message);
      closeModal();
      dispatch(setParticipantToggle());
    } catch (err) {
      errorToastMessage(err as Error);
    } finally {
      setSubmitLoader(false);
    }
  };

  return (
    <Modal open={openModal} onClose={closeModal}>
      <Box sx={{ ...ModalBaseStyles, minHeight: "20vh" }}>
        <ModalHeader
          title="Drop Off/Withdraw participant"
          onCloseClick={closeModal}
        />
        <Typography sx={{ ...LabelStyle, mb: 3 }}>
          Are you sure you want to mark this participant as{" "}
          {participant.status === "unverified"
            ? "withdrawn pre-randomization"
            : "withdrawn post-randomization"}
          ? This action is irreversible. If so, please supply a reason.
        </Typography>
        <Box sx={{ mb: 3 }}>
          <FormControl sx={InputWrapper}>
            <FormLabel sx={LabelStyle} htmlFor="reason">
              Reason <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <TextField
              id="reason"
              multiline
              fullWidth
              onChange={changeReasonHandler}
            />
          </FormControl>
        </Box>
        <Box sx={{ mb: 3 }}>
          <FormControl sx={InputWrapper}>
            <FormLabel sx={LabelStyle} htmlFor="reason">
              Date <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <DatePicker
              value={date ? DateTime.fromFormat(date, "dd/MM/yyyy") : null}
              format="dd/MM/yyyy"
              onChange={changeDateHandler}
              slotProps={{
                textField: {
                  inputProps: {
                    readOnly: true,
                    placeholder: "Select date",
                    id: "date",
                  },
                },
              }}
            />
          </FormControl>
        </Box>
        <Box sx={ModalActionButtonStyles}>
          {!submitLoader ? (
            <>
              <Button variant="outlined" onClick={closeModal}>
                Cancel
              </Button>
              <Button type="submit" variant="contained" onClick={submitHandler}>
                Save
              </Button>
            </>
          ) : (
            <CircularProgress size={25} />
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmDropOffModal;
